import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FileResponse,
  IQueryReports,
  ReportListResponse,
} from './api-reports.types';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiReportsService {
  constructor(private http: HttpClient) {}

  async getReportsList(body: IQueryReports): Promise<ReportListResponse> {

      const url = `${environment.backend}/report/list`;
      return new Promise((resolve, reject) => {
        this.post<ReportListResponse>(url, body).subscribe({
          next: (data) => {
            resolve(data);
          },
          error: (err) => {
            reject(err);
          },
        });
      });

  }

  async getReportToDownload(idReport: string): Promise<string> {
    const url = `${environment.backend}/report/url`;
    return new Promise((resolve, reject) => {
      this.post<FileResponse>(url, { id: idReport }).subscribe({
        next: (data) => {
          resolve(data.signedUrl);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  private post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {        return throwError(() => error);
      })
    );
  }
}
