<p-toast position="bottom-right" key="global" [baseZIndex]="10000">
  <ng-template let-message pTemplate="message">
    <div class="flex" style="flex: 1">
      <div class="text-center">
        <span style="vertical-align: middle">{{ message.summary }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
<router-outlet> </router-outlet>
