import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import {
  ConfigurationBranch,
  IQueryTransactions,
  ListTransactionsResponse,
  TransactionDetails,
} from './api-transactions.types';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiTransactionsService {
  constructor(private http: HttpClient) {}

  async getTransactionsList(
    body: IQueryTransactions
  ): Promise<ListTransactionsResponse> {
    const url = `${environment.backend}/transaction/list`;
    return new Promise((resolve, reject) => {
      this.post<ListTransactionsResponse>(url, body).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  async getBranchesByIds(
    branchesIds: string[]
  ): Promise<ConfigurationBranch[]> {
    const url = `${environment.backend}/configuration/branches`;
    return new Promise((resolve, reject) => {
      this.post<ConfigurationBranch[]>(url, { branchesIds }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  async getTransactionDetail(
    transactionId: string
  ): Promise<TransactionDetails> {
    const url = `${environment.backend}/transaction/detail`;
    return new Promise((resolve, reject) => {
      this.post<TransactionDetails>(url, { transactionId }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  private post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
