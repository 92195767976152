import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './modules/empty-route/empty-route.component';
import { RouterModule } from '@angular/router';
import {
  RSA_PARAMETER,
  TokenInterceptor,
  VerifySignInterceptor,
} from '@deuna/bp-sh-helpers-ng';
import { registerLocaleData } from '@angular/common';
import localeEc from '@angular/common/locales/es-EC';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ApiReportsService } from './services/api-external/report/api-reports.service';
import { ApiTransactionsService } from './services/api-external/transactions/api-transactions.service';
registerLocaleData(localeEc);

@NgModule({
  declarations: [AppComponent, EmptyRouteComponent],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VerifySignInterceptor,
      multi: true,
    },
    {
      provide: RSA_PARAMETER,
      useValue: localStorage.getItem('RSA_PARAMETER'),
    },
    MessageService,
    ApiReportsService,
    ApiTransactionsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
