<p-toast position="bottom-right" key="global" [baseZIndex]="10000">
  <ng-template let-message pTemplate="message">
    <div class="flex" style="flex: 1">
      <div class="text-center">
        <i class="material-icons material-icons-outlined" style="vertical-align: middle; padding-right: 4px"
          aria-hidden="true" *ngIf="message?.icon !== 'empty_icon'">{{ message?.icon ?? "" }}</i>
        <span style="vertical-align: middle">{{ message.summary }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
<router-outlet> </router-outlet>