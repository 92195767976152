import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FileResponse,
  IQueryReports,
  ReportListResponse,
} from './api-external.types';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiExternalService {
  constructor(private http: HttpClient) {}

  async getReportsList(body: IQueryReports): Promise<ReportListResponse> {
    const observable: Observable<ReportListResponse> =
      this.http.post<ReportListResponse>(
        `${environment.backend}/report/list`,
        body
      );

    return new Promise((resolve, reject) => {
      observable.subscribe({
        next: (data: ReportListResponse) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  async getReportToDownload(idReport: string): Promise<string> {
    const observable: Observable<FileResponse> = this.http.post<FileResponse>(
      `${environment.backend}/report/url`,
      { id: idReport }
    );

    return new Promise((resolve, reject) => {
      observable.subscribe({
        next: (data: FileResponse) => {
          resolve(data.signedUrl);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
}
